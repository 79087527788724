/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'

import styled from 'styled-components'

import { CardExames } from '@monorepo-amais/commons/components/exames'
import {
	BreadcrumbAuto,
	MainContainer,
	Content,
	Div,
	Grid as BlockGrid,
	Pagination
} from '@monorepo-amais/commons/components/Blocks'
import { SearchExams, CheckBox, CheckboxContainer, CheckboxSpan } from '@monorepo-amais/commons/components/Inputs'

import { theme } from 'styled-tools'

import Loading from '@monorepo-amais/commons/components/Blocks/Loading'
import { FaAngleRight } from 'react-icons/fa'
import { MainTitle } from '@monorepo-amais/commons/components/Typography'
// import { URL_ELASTIC, PAGE_LIMIT_EXAM } from '@monorepo-amais/commons/utils/enums'
import { PAGE_LIMIT_EXAM } from '@monorepo-amais/commons/utils/enums'
import { useCallback } from 'react'
import sanitizeRegex from '@monorepo-amais/commons/utils/sanitizeRegex'
import axios from '@monorepo-amais/commons/utils/base-axios'

// const urlExam = `${process.env.GATSBY_ELASTIC_URL}/${process.env.COSMIC_BUCKET}-exames/_search`

const FilterWrapper = styled.div`
	border-radius: 45px;
	border: solid 1px ${props => props.theme.colors.primary};
	background-color: ${theme('colors.white')};
	color: ${props => props.theme.colors.primary};

	height: fit-content;
	margin-top: 32px;
	margin-bottom: 32px;

	@media (min-width: 768px) {
		display: ${({ mobile }) => (mobile ? 'none' : '')};
	}
	@media (max-width: 768px) {
		margin: ${({ mobile, m }) => (mobile && m ? m : '')};
	}
`

const FilterByTypeWrapper = styled.div`
	border-radius: 10px;
	border: solid 1px ${props => props.theme.colors.graye2};
	background-color: ${props => props.theme.colors.grayf5};
	padding: 8px;
	height: fit-content;
	margin-bottom: 16px;
`

const TopExamsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	border: solid 1px ${props => props.theme.colors.graye2};
	background-color: ${props => props.theme.colors.grayf5};
	padding: 8px;
	height: fit-content;
	margin-bottom: 16px;

	@media (max-width: 767px) {
		display: none;
	}
`

const EmptyWrapper = styled.p`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${theme('colors.primary')};
	font-size: 24px;
	margin: 60px 0;
`

const SubTitleText = styled.p`
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	margin-top: 8px;
	margin-left: 5px;
	color: ${props => props.theme.colors.black_fonts};
`

const TopExame = styled.div`
	cursor: pointer;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${theme('colors.black_fonts')};
	background-color: #ffff;
	border: solid 1px #ffff;
	border-radius: 4px;
	padding: 0 15px 0 15px;
	margin-bottom: 15px;

	p {
		width: 100%;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: ${props => props.theme.colors.gray46};
	}
`

const ExamesCounter = styled.div`
	width: auto;
	background-color: ${props => props.theme.colors.grayf7};
	border-radius: 11px;
	margin-bottom: 20px;

	p {
		font-size: 14px;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.07px;
		text-align: left;
		color: ${theme('colors.black_fonts')};
		padding: 5px 20px;
		margin: 0;
	}
`

const RightArea = styled.div`
	grid-area: r;
`

const CustomContent = styled(Content)`
	grid-template-areas: 'l l l l r r r r r r r r r r r r r';

	margin: 0 0 20px 0;

	@media (max-width: 1024px) {
		padding: 10px;
		margin: 0;
	}

	@media (max-width: 991.98px) {
		// margin: 10px;
	}

	@media (max-width: 768px) {
		margin: 0;
		padding: 0;
		//display: ${({ mobile }) => (mobile ? 'none' : '')};
	}

	.right {
		@media (max-width: 768px) {
			/* padding: 0 20px; */
		}
	}
`

/**
 * Pagina de lista de exames: busca, ordena, exibe por paginacao
 * @param {*} {data} informações da query do cosmic
 */
function Exames({ data }) {
	const [exames, setExames] = useState([])

	const initialStateTop = data.allTopExames.edges.map(({ node }) => node)

	const [topExams] = useState(initialStateTop)
	const [letter, setLetter] = useState('')
	const [query, setQuery] = useState('')
	const [isSearching, setIsSearching] = useState(false)
	const [filter, setFilter] = useState('')
	const [totalPages, setTotalPages] = useState(0)
	const [totalExamsFound, setTotalExamsFound] = useState(0)
	const pageTitle = process.env.GATSBY_COSMIC_BUCKET === 'irn' ? 'Exames e preparo' : 'Exames e vacinas'
	const placeholderSearch = process.env.GATSBY_COSMIC_BUCKET === 'irn' ? 'Buscar exame' : 'Buscar exame ou vacina'

	// Se vindo de detalhe de exames, recupera a sessao e vai para a ultima pagina visualizada
	// TODO Aguardando especificacoes da feature para prod
	let initialPage = 1
	if (process.env.GATSBY_HML && typeof Storage !== 'undefined') {
		const sessionPage = sessionStorage.getItem('pageExams')
		if (sessionPage) {
			initialPage = Number(sessionPage)
			sessionStorage.removeItem('pageExams')
		}
	}
	const [page, setPage] = useState(initialPage)

	const handleChangePage = page => {
		setPage(page)
	}

	const handleChangeFilter = e => setQuery(e.target.value.replace(sanitizeRegex, ''))
	const [examsClassification, setExamsClassification] = useState([
		{
			classification: 'Exames de sangue, urina e mais',
			selected: false,
			initials: 'AC',
			quantity: 0
		},
		{
			classification: 'Exames de imagem',
			selected: false,
			initials: 'CD',
			quantity: 0
		},
		{
			classification: 'Vacinas e outros procedimentos',
			selected: false,
			initials: 'CJ',
			quantity: 0
		}
	])
	const [changedFilter, setChangedFilter] = useState(false)
	const [queryFilter, setQueryFilter] = useState([])

	const handleSearch = e => {
		if (query !== filter) {
			setFilter(query)
			setLetter('')
			setPage(1)
		}

		//Tira o foco do input pro teclado do mobile desaparecer
		//e.target.blur()
	}

	useEffect(() => {
		if (changedFilter) {
			// eslint-disable-next-line array-callback-return
			examsClassification.map(item => {
				if (item.selected) {
					queryFilter.push({
						match: {
							'classification.initials': item.initials
						}
					})
				}
			})

			if (page > 1) {
				setPage(1)
			} else {
				fetchData()
			}
			setChangedFilter(false)
		}
	}, [changedFilter])

	const fetchData = async () => {
		setIsSearching(true)

		// ****** ******
		// Filtro aplicado para exame 6983 para amais PI
		// após ajuste pelo back o filtro deverá ser retirado
		// ****** ******
		const queryMatch =
			process.env.COSMIC_BUCKET === 'amaispi'
				? {
						query: {
							bool: {
								must: [
									{
										query_string: {
											query: `*${query.replace(sanitizeRegex, '')}*`,
											fields: ['name', 'synonyms', 'slug', 'productInitials']
										}
									},
									{
										bool: {
											should: queryFilter
										}
									}
								],
								must_not: [
									{
										term: {
											id: 6983
										}
									}
								]
							}
						}
				  }
				: {
						query: {
							bool: {
								must: [
									{
										query_string: {
											query: `*${query.replace(sanitizeRegex, '')}*`,
											fields: ['name', 'synonyms', 'slug', 'productInitials']
										}
									},
									{
										bool: {
											should: queryFilter
										}
									}
								]
							}
						}
				  }

		try {
			const data = await axios.post('/elasticsearch/digital/free-search', {
				search: {
					...queryMatch,
					from: (page - 1) * PAGE_LIMIT_EXAM,
					size: PAGE_LIMIT_EXAM
				},
				index: `${process.env.GATSBY_COSMIC_BUCKET}-exames`
			})

			const unfilteredQuery = {
				query: {
					bool: {
						must: {
							query_string: {
								query: `*${query.replace(sanitizeRegex, '')}*`,
								fields: ['name', 'synonyms', 'slug', 'productInitials']
							}
						}
					}
				}
			}

			const countClassification = await axios.post('/elasticsearch/digital/free-search', {
				search: {
					...unfilteredQuery,
					size: 0,
					aggs: {
						classification_count_ac: {
							filter: {
								term: {
									'classification.initials.keyword': 'AC'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						},
						classification_count_cd: {
							filter: {
								term: {
									'classification.initials.keyword': 'CD'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						},
						classification_count_cj: {
							filter: {
								term: {
									'classification.initials.keyword': 'CJ'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						}
					}
				},
				index: `${process.env.GATSBY_COSMIC_BUCKET}-exames`
			})

			setExamsClassification(prevArray =>
				prevArray.map(item => {
					if (item.initials === 'AC') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_ac.doc_count }
					} else if (item.initials === 'CD') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_cd.doc_count }
					} else if (item.initials === 'CJ') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_cj.doc_count }
					} else {
						return item
					}
				})
			)

			const results = data.data.hits.hits.map(exam => {
				const { _id, destaque, name, synonyms, sobre, slug } = exam._source
				return { id: _id, destaque, nome: name, sinonimia: synonyms, sobre, slug }
			})
			setExames(results)
			setTotalPages(parseInt(data.data.hits.total.value / PAGE_LIMIT_EXAM) + 1)
			setTotalExamsFound(parseInt(data.data.hits.total.value))
		} catch {
			console.error('Error na requisicao do elastic para exames')
		}
		setIsSearching(false)
	}

	useEffect(() => {
		handleSearch()
	}, [query])

	useEffect(() => {
		setExames([])
		setTotalPages(0)
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, filter])

	const examsFiltered = exames.filter(exam => {
		if (letter) {
			if (letter !== '0-9') {
				return exam.name[0].toLowerCase() === letter
			} else {
				return '0123456789'.includes(exam.name[0].toLowerCase())
			}
		}
		return true
	})

	const handleClickFilter = position => {
		setQueryFilter([])
		setChangedFilter(true)
		setExamsClassification(prevArray =>
			prevArray.map((item, index) => (index === position ? { ...item, selected: !item.selected } : item))
		)
	}

	const navigateTo = path => () => {
		navigate(path)
		setPageSession()
	}

	const setPageSession = useCallback(() => {
		// Apenas para ambiente de dev para executar os testes automatizados
		// TODO Aguardando especificacoes da feature para prod
		if (process.env.GATSBY_HML && typeof Storage !== 'undefined') {
			sessionStorage.setItem('pageExams', `${page}`)
		}
	}, [page])

	return (
		<MainContainer>
			<BreadcrumbAuto title='Exames' />
			<CustomContent mobile={true}>
				<Div className='left'>
					<MainTitle id='title-exam'>{pageTitle}</MainTitle>
				</Div>
			</CustomContent>
			<FilterWrapper>
				<SearchExams
					id='exam-search'
					idButton='button-exam-search'
					placeholder={placeholderSearch}
					onChange={handleChangeFilter}
					value={query}
					onKeyPress={handleSearch}
					onSearch={handleSearch}
				/>
			</FilterWrapper>
			<CustomContent>
				<Div className='left'>
					<FilterByTypeWrapper>
						<SubTitleText>Filtrar</SubTitleText>
						{examsClassification?.map(({ classification, initials, quantity, selected }, index) => (
							<CheckboxContainer
								id={classification}
								onClick={() => (quantity > 0 ? handleClickFilter(index) : null)}
								style={{ cursor: quantity > 0 ? 'pointer' : 'default' }}
								key={classification}
							>
								<CheckBox
									checked={selected}
									disabled={quantity === 0}
									type='checkbox'
									name={classification}
									style={{
										cursor: quantity > 0 ? 'pointer' : 'default'
									}}
									onChange={() => {}}
								/>
								<label style={{ cursor: quantity > 0 ? 'pointer' : 'default' }} htmlFor={classification}>
									{classification}
								</label>
								<CheckboxSpan>({quantity})</CheckboxSpan>
							</CheckboxContainer>
						))}
					</FilterByTypeWrapper>
					<TopExamsWrapper>
						<SubTitleText>Mais buscados</SubTitleText>
						{topExams.map((e, index) => (
							<TopExame
								id={`top-exam-${index}`}
								onClick={navigateTo(`/exames/${e.slug}/`)}
								key={`exam-more-searched-${e.slug}`}
							>
								<p>{e.name}</p>
								<FaAngleRight size='15px' />
							</TopExame>
						))}
					</TopExamsWrapper>
				</Div>
				<RightArea>
					<ExamesCounter className='cards'>
						<p>
							Exibindo {exames.length} de {totalExamsFound} exames
						</p>
					</ExamesCounter>
					<MediaQuery maxDeviceWidth={768}>
						<BlockGrid columns='1' className='right'>
							{isSearching && <Loading />}
							<Pagination
								elementToRender={e => <CardExames onClick={navigateTo(`/exames/${e.slug}/`)} key={e.slug} {...e} />}
								elementsPerPage={PAGE_LIMIT_EXAM}
								totalPages={totalPages}
								handleNextPage={handleChangePage}
								items={examsFiltered}
								page={page}
								needFetch={true}
								mode='mobile'
							/>
							{examsFiltered.length < 1 && !isSearching && <EmptyWrapper>Nenhum resultado encontrado.</EmptyWrapper>}
						</BlockGrid>
					</MediaQuery>
					<MediaQuery minDeviceWidth={768}>
						<BlockGrid columns='1' className='right' m={!examsFiltered.length ? '0 auto auto' : ''}>
							{/* {isSearching ? <Loading /> : examsFiltered.map(exame => <CardExames key={exame.id} {...exame} />)} */}
							{isSearching && <Loading />}
							<Pagination
								elementToRender={e => <CardExames key={e.slug} {...e} setPageSession={setPageSession} />}
								elementsPerPage={PAGE_LIMIT_EXAM}
								totalPages={totalPages}
								handleNextPage={handleChangePage}
								items={examsFiltered}
								page={page}
								needFetch={true}
								mode='desktop'
							/>
							{examsFiltered.length < 1 && !isSearching && <EmptyWrapper>Nenhum resultado encontrado.</EmptyWrapper>}
						</BlockGrid>
					</MediaQuery>
				</RightArea>
			</CustomContent>
		</MainContainer>
	)
}

export default Exames
